<template>
  <v-container
    :key="pageKey"
    fluid
    style="
      background: rgba(255, 255, 255, 0.9);
      min-width: 100%;
      min-height: 95vh;
      padding: 10px 20px;
      position: relative;
    "
  >
    <!-- <h6
      @click="back"
      style="border:1px solid rgba(0,0,0,0.3); border-radius:5px; position:absolute; width:90px; cursor:pointer;"
    >
      <v-icon style="position:relative; top:-1px;">mdi-chevron-left</v-icon
      >kembali
    </h6> -->
    <div style="margin-bottom: 65px">
      <div style="border-bottom: 1px solid rgba(0, 0, 0, 0.2)">
        <div
          class="d-flex justify-start align-start"
          style="position: relative"
        >
          <h4 class="fpb-title">DETAIL PJCA</h4>
          <v-tooltip bottom color="grey">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                x-small
                text
                icon
                color="grey darken-2"
                @click="loadPage"
                style="position: relative; top: 12px"
              >
                <v-icon small>mdi-refresh</v-icon>
              </v-btn>
            </template>
            <span>refresh page</span>
          </v-tooltip>
          <v-spacer></v-spacer>
          <div class="actionButton">
            <!-- Admin -->

            <div>
              <div>
                <v-btn
                  type="button"
                  elevation="0"
                  color="primary"
                  class="white--text font-weight-bold ma-1"
                  style="font-size: 12px"
                  :loading="loading"
                  small
                  :rounded="wWidth < 809"
                >
                  Save
                </v-btn>
                <v-btn
                  type="button"
                  elevation="0"
                  color="primary"
                  class="white--text font-weight-bold ma-1"
                  style="font-size: 12px"
                  :loading="loading"
                  small
                  :rounded="wWidth < 809"
                >
                  Edit
                </v-btn>
                <v-btn
                  type="button"
                  elevation="0"
                  color="green"
                  class="white--text font-weight-bold ma-1"
                  style="font-size: 12px"
                  :loading="loading"
                  :disabled="loading"
                  small
                  :rounded="wWidth < 809"
                >
                  Submit
                </v-btn>
                <v-btn
                  type="button"
                  elevation="0"
                  color="primary"
                  class="white--text font-weight-bold ma-1"
                  style="font-size: 12px"
                  :loading="loading"
                  :disabled="loading"
                  small
                  :rounded="wWidth < 809"
                >
                  Set to Draft
                </v-btn>

                <v-btn
                  type="button"
                  elevation="0"
                  color="green"
                  class="white--text font-weight-bold ma-1"
                  style="font-size: 12px"
                  :loading="loading"
                  small
                  :rounded="wWidth < 809"
                >
                  Done
                </v-btn>
                <v-btn
                  type="button"
                  elevation="0"
                  color="pink"
                  class="white--text font-weight-bold ma-1"
                  style="font-size: 12px"
                  :loading="loading"
                  :disabled="loading"
                  small
                  :rounded="wWidth < 809"
                >
                  Return
                </v-btn>
                <v-btn
                  type="button"
                  elevation="0"
                  color="pink"
                  class="white--text font-weight-bold ma-1"
                  style="font-size: 12px"
                  :loading="loading"
                  :disabled="loading"
                  small
                  :rounded="wWidth < 809"
                >
                  Cancel
                </v-btn>
              </div>
            </div>
          </div>
        </div>
      </div>
      <v-row no-gutters>
        <v-col cols="3">
          <p style="font-size: 12px">
            Tgl. PJCA: {{ '01/01/2024 | Play Console' }}
          </p>
        </v-col>
        <v-col cols="3">
          <p style="font-size: 12px">
            Tgl. CA: {{ '01/10/2024 | Yuni Yulidan' }}
          </p>
        </v-col>
        <v-col cols="6">
          <div class="d-flex justify-end">
            <p
              style="
                background-color: grey;
                color: white;
                font-weight: bold;
                margin: 5px 0 0 0;
                padding: 0 10px;
                border: 1px solid rgba(0, 0, 0, 0.6);
                width: 180px;
                text-align: center;
              "
            >
              DRAFT
            </p>
          </div>
        </v-col>
      </v-row>
      <v-row no-gutters style="margin-top: 40px">
        <v-col cols="12" md="3" class="d-flex flex-column pl-3 pr-3">
          <p
            class="text-left"
            style="
              margin: 0;
              font-size: 12px;
              font-weight: bold;
              color: rgba(0, 0, 0, 0.5);
            "
          >
            No. Dokumen
          </p>

          <v-text-field
            :value="'SMI/001/002312'"
            dense
            readonly
            style="font-size: 12px; height: 45px"
          />
        </v-col>
        <v-col cols="12" md="3" class="d-flex flex-column pl-3 pr-3">
          <p
            class="text-left"
            style="
              margin: 0;
              font-size: 12px;
              font-weight: bold;
              color: rgba(0, 0, 0, 0.5);
            "
          >
            No. Payreq
          </p>

          <v-text-field
            :value="'3245321'"
            dense
            readonly
            style="font-size: 12px; height: 45px"
          />
        </v-col>
        <v-col cols="12" md="3" class="d-flex flex-column pl-3 pr-3">
          <p
            class="text-left"
            style="
              margin: 0;
              font-size: 12px;
              font-weight: bold;
              color: rgba(0, 0, 0, 0.5);
            "
          >
            PIC
          </p>

          <v-text-field
            :value="'Play COnsole'"
            dense
            readonly
            style="font-size: 12px; height: 45px"
          /> </v-col
        ><v-col cols="12" md="3" class="d-flex flex-column pl-3 pr-3"></v-col>
        <v-col cols="12" md="3" class="d-flex flex-column pl-3 pr-3">
          <p
            class="text-left"
            style="
              margin: 0;
              font-size: 12px;
              font-weight: bold;
              color: rgba(0, 0, 0, 0.5);
            "
          >
            Perusahaan
          </p>

          <v-text-field
            :value="'PT. SanQua Multi Internasional'"
            dense
            readonly
            style="font-size: 12px; height: 45px"
          />
        </v-col>
        <v-col cols="12" md="3" class="d-flex flex-column pl-3 pr-3">
          <p
            class="text-left"
            style="
              margin: 0;
              font-size: 12px;
              font-weight: bold;
              color: rgba(0, 0, 0, 0.5);
            "
          >
            Kepada Department
          </p>

          <v-text-field
            :value="'FINANCE'"
            dense
            readonly
            style="font-size: 12px; height: 45px"
          />
        </v-col>
        <v-col cols="12" md="3" class="d-flex flex-column pl-3 pr-3">
          <p
            class="text-left"
            style="
              margin: 0;
              font-size: 12px;
              font-weight: bold;
              color: rgba(0, 0, 0, 0.5);
            "
          >
            Dari Department
          </p>

          <v-text-field
            :value="'PRO/MIS/IC'"
            dense
            readonly
            style="font-size: 12px; height: 45px"
          />
        </v-col>
      </v-row>
      <v-card style="margin-top: 40px">
        <v-tabs
          v-model="tab"
          background-color="rgb(240,240,240)"
          slider-color="primary"
          color="primary"
        >
          <v-tab v-for="item in items" :key="item.tab">
            {{ item.tab }}
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
          <v-tab-item
            v-for="item in items"
            :key="item.tab"
            style="padding: 20px"
          >
            <div style="min-height: 60vh">
              <div v-if="item.tab == 'Detail Barang'">
                <v-data-table
                  height="50vh"
                  mobile-breakpoint="0"
                  style="cursor: pointer; border: 1px solid rgba(0, 0, 0, 0.2)"
                  :headers="pjcaHeaders"
                  :items="detailItems"
                  :items-per-page="10"
                  @click:row="rowClick"
                  item-key="id"
                >
                  <template v-slot:top>
                    <div
                      style="
                        border: thin solid rgba(0, 0, 0, 0.12);
                        cursor: auto;
                        padding: 0 15px;
                        height: 75px;
                        display: flex;
                        overflow: hidden;
                      "
                    >
                      <div
                        style="
                          display: flex;
                          align-items: center;
                          margin: 0 15px 0 5px;
                        "
                      >
                        <v-btn
                          @click="openItemForm"
                          style="font-size: 10px"
                          elevation="2"
                          tilerounded="5px"
                          x-small
                          color="primary"
                          class="white--text"
                        >
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon v-bind="attrs" v-on="on" small class="">
                                mdi-plus
                              </v-icon>
                            </template>
                            <span>Tambah item</span>
                          </v-tooltip>
                        </v-btn>
                      </div>
                      <div style="width: 120px">
                        <v-select
                          label="Action"
                          style="
                            position: relative;
                            top: 15px;
                            margin: 0 15px 0 5px;
                            font-size: 12px;
                          "
                          item-text="name"
                          item-value="id"
                          outlined
                          return-id
                          dense
                        ></v-select>
                      </div>
                      <div
                        style="
                          width: 260px;
                          position: relative;
                          top: 15px;
                          margin: 0 15px 0 5px;
                          font-size: 12px;
                          height: 45px;
                        "
                      >
                        <v-text-field
                          label="Cari barang"
                          outlined
                          dense
                        ></v-text-field>
                      </div>
                      <v-spacer></v-spacer>
                      <div style="display: flex; align-items: center">
                        <v-btn small color="primary" style="margin-right: 10px">
                          <v-icon color="white" small style="margin-right: 5px"
                            >mdi-file-outline</v-icon
                          >
                          <a href="#" style="font-weight: bold; color: white"
                            >Dokumen</a
                          >
                        </v-btn>
                        <v-btn small color="primary" style="margin-right: 10px">
                          <v-icon color="white" small style="margin-right: 5px"
                            >mdi-magnify
                          </v-icon>
                          <a href="#" style="font-weight: bold; color: white"
                            >Lihat PDF</a
                          >
                        </v-btn>
                      </div>
                    </div>
                  </template>
                  <template v-slot:[`item.no`]="{ index }">
                    <div style="width: 35px">
                      {{ index + 1 }}
                    </div>
                  </template>
                  <template v-slot:[`item.product_code`]="{ item }">
                    <div style="width: 95px; font-size: 12px">
                      {{ item.product_code }}
                    </div>
                  </template>
                  <template v-slot:[`item.product_name`]="{ item }">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <div
                          v-bind="attrs"
                          v-on="on"
                          style="
                            text-align: left;
                            width: 250px;
                            font-size: 12px;
                            padding: 0;
                          "
                        >
                          {{
                            `${
                              item.product_code !== null
                                ? '(' + item.product_code + ')'
                                : ''
                            } ${item.product_name}`
                          }}
                        </div>
                      </template>
                      <span>
                        {{
                          `${
                            item.product_code !== null
                              ? '(' + item.product_code + ')'
                              : ''
                          } ${item.product_name}`
                        }}</span
                      >
                    </v-tooltip>
                  </template>
                  <!-- <template v-slot:[`item.uom`]="{ item }">
                    <div style="width: 35px; font-size: 12px">
                      {{ item.uom }}
                    </div>
                  </template> -->
                  <template v-slot:[`item.qty`]="{ item }">
                    <div style="width: 45px; font-size: 12px">
                      {{ item.qty }}
                    </div>
                  </template>
                  <template v-slot:[`item.price`]="{ item }">
                    <div style="width: 45px; font-size: 12px">
                      {{ item.price }}
                    </div>
                  </template>
                  <template v-slot:[`item.realization_qty`]="{ item }">
                    <div style="width: 45px; font-size: 12px">
                      {{ item.realization_qty }}
                    </div>
                  </template>
                  <template v-slot:[`item.realization_price`]="{ item }">
                    <div style="width: 45px; font-size: 12px">
                      {{ item.realization_price }}
                    </div>
                  </template>
                  <template v-slot:[`item.realization_total`]="{ item }">
                    <div style="width: 45px; font-size: 12px">
                      {{ item.realization_total }}
                    </div>
                  </template>
                  <template v-slot:[`item.desc`]="{ item }">
                    <div style="width: 75px; font-size: 12px">
                      {{ item.desc }}
                    </div>
                  </template>
                </v-data-table>

                <v-row no-gutters style="margin-top: 20px">
                  <v-col
                    cols="12"
                    md="4"
                    class="d-flex justify-content-between align-center"
                  >
                    <p
                      class="text-left"
                      style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                    >
                      Discount
                    </p>
                    <div style="width: 125px; height: 45px">
                      <v-text-field
                        :value="0"
                        dense
                        readonly
                        reverse
                        style="font-size: 12px"
                      />
                    </div>
                  </v-col>
                  <v-col md="8"></v-col>
                  <v-col
                    cols="12"
                    md="4"
                    class="d-flex justify-content-between align-center"
                  >
                    <p
                      class="text-left"
                      style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                    >
                      Pajak
                    </p>
                    <div style="width: 125px; height: 45px">
                      <v-text-field
                        :value="0"
                        dense
                        readonly
                        reverse
                        style="font-size: 12px"
                      />
                    </div>
                  </v-col>
                  <v-col md="8"></v-col>
                  <v-col
                    cols="12"
                    md="4"
                    class="d-flex justify-content-between align-center"
                  >
                    <p
                      class="text-left"
                      style="
                        margin: 0;
                        font-size: 14px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                    >
                      TOTAL
                    </p>
                    <div style="width: 125px; height: 45px">
                      <v-text-field
                        :value="'16.530.000'"
                        dense
                        readonly
                        reverse
                        style="font-size: 14px"
                      />
                    </div>
                  </v-col>
                  <v-col md="8"></v-col>
                  <v-col
                    cols="12"
                    class="d-flex justify-content-between align-center"
                  >
                    <p
                      class="text-left"
                      style="
                        margin: 0;
                        font-size: 16px;
                        color: rgba(0, 0, 0, 0.7);
                      "
                    >
                      Terbilang: Enam Belas Juta Lima Ratus Tiga Puluh Ribu
                      Rupiah
                    </p>
                  </v-col>
                </v-row>
              </div>

              <div v-if="item.tab == 'Approval'">
                <div style="margin-top: 20px">
                  <v-row no-gutters style="padding: 0">
                    <v-col md="12" style="padding: 0">
                      <!-- <div
                        style="
                          margin-left: 5px;
                          font-weight: bold;
                          margin-bottom: 10px;
                          border-bottom: 1px solid rgba(0, 0, 0, 0.4);
                        "
                      >
                        APPROVAL MATRIX
                      </div> -->
                      <v-data-table
                        mobile-breakpoint="0"
                        :headers="approvalHeaders"
                        :items="[]"
                        :items-per-page="10"
                        class="elevation-1 mx-auto"
                        hide-default-footer
                      >
                        <!-- <template v-slot:[`item.document_no`]="{ item }">
                          <div style="width: 140px; font-size: 12px">
                            {{ item.document_no }}
                          </div>
                        </template>
                        <template v-slot:[`item.table_name`]="{ item }">
                          <div style="width: 140px; font-size: 12px">
                            {{ item.table_name }}
                          </div>
                        </template>
                        <template v-slot:[`item.approver_user`]="{ item }">
                          <div style="cursor: pointer" v-if="item">
                            <v-chip
                              label
                              outlined
                              small
                              style="
                                margin-right: 5px;
                                padding: 0 3px;
                                cursor: pointer;
                                border: 1px solid rgba(0, 0, 0, 0.5);
                              "
                              v-for="(item, index) in item.approver_user"
                              :key="index"
                            >
                              {{ item.user !== null ? item.user.name : '-' }}
                            </v-chip>
                          </div>
                        </template>
                        <template v-slot:[`item.approved`]="{ item }">
                          <div
                            v-for="(items, index) in item.approver_user"
                            :key="index"
                            style="width: 140px"
                          >
                            <v-chip
                              v-if="items.status === 1"
                              label
                              outlined
                              small
                              style="
                                margin-right: 5px;
                                padding: 0 3px;
                                cursor: pointer;
                                border: 1px solid rgba(0, 0, 0, 0.5);
                              "
                            >
                              {{ item.user !== null ? items.user.name : '-' }}
                            </v-chip>
                          </div>
                        </template>
                        <template v-slot:[`item.rejected`]="{ item }">
                          <div
                            v-for="(items, index) in item.approver_user"
                            :key="index"
                            style="width: 140px"
                          >
                            <v-chip
                              v-if="items.status === -1"
                              label
                              outlined
                              small
                              style="
                                margin-right: 5px;
                                padding: 0 3px;
                                cursor: pointer;
                                border: 1px solid rgba(0, 0, 0, 0.5);
                              "
                            >
                              {{ item.user !== null ? items.user.name : '-' }}
                            </v-chip>
                          </div>
                        </template>
                        <template v-slot:[`item.created_at`]="{ item }">
                          <div style="width: 140px; font-size: 12px">
                            {{ item.created_at }}
                          </div>
                        </template>
                        <template v-slot:[`item.updated_at`]="{ item }">
                          <div
                            v-for="(items, index) in item.approver_user"
                            :key="index"
                            style="width: 140px; font-size: 12px"
                          >
                            <span v-if="items.status === 1">
                              {{
                                items.updated_at !== null
                                  ? items.updated_at
                                  : '-'
                              }}
                            </span>
                            <br v-if="index > 0" />
                          </div>
                        </template> -->
                      </v-data-table>
                    </v-col>
                  </v-row>
                  <div style="margin-top: 20px">
                    <div style="text-align: left; padding-left: 5px">
                      <v-btn
                        color="grey"
                        tile
                        dark
                        small
                        dense
                        style="font-size: 10px; width: 100px"
                      >
                        <span>Fetch Matrix</span>
                      </v-btn>
                    </div>
                  </div>
                </div>
                <!-- <h4
                  v-else
                  class="d-flex justify-center align-center"
                  style="
                    color: grey;
                    font-weight: bold;
                    font-size: 16px;
                    margin-top: 25px;
                  "
                >
                  APPROVAL TIDAK DITEMUKAN
                </h4> -->
              </div>
            </div>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </div>
    <div>
      <FormItemPjca />
    </div>
    <v-snackbar
      top
      elevation="0"
      color="white"
      v-model="snackbar"
      :timeout="timeout"
    >
      <v-alert outlined type="error" prominent border="left">
        {{ alertText }}
      </v-alert>
    </v-snackbar>
    <div
      v-if="loading"
      class="d-flex justify-center align-center"
      style="
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 5;
        background: rgba(0, 0, 0, 0.1);
      "
    >
      <v-progress-circular
        :size="100"
        color="primary"
        indeterminate
        class="d-flex justify-center align-center"
      >
        <p
          class="d-flex justify-center align-center"
          style="font-size: 12px; margin: auto"
        >
          Please wait
        </p>
      </v-progress-circular>
    </div>
  </v-container>
</template>
<script>
import 'vue-pdf-app/dist/icons/main.css'
import axios from 'axios'
import buildType from '../../../services/buildType'
import { mapGetters, mapMutations } from 'vuex'
// import moment from 'moment'
export default {
  components: {
    FormItemPjca: () => import('./ItemForm')
  },
  data() {
    return {
      pageKey: 0,
      config: require('../../../services/config.json')[
        process.env.VUE_APP_BUILD_TYPE
      ],
      wWidth: window.innerWidth,
      oAuth: buildType.apiURL('oauth'),
      proc: buildType.apiURL('proc'),
      upload: buildType.apiURL('upload2'),
      pagePath: this.$route.params.path,
      isFpbPageEdit: false,
      isEdit: false,
      rabDialog: false,
      submitToPrDialog: false,
      pdfDialog: false,
      dokumenDialog: false,
      closeDialog: false,

      checkbox: false,
      detail: null,
      searchItem: '',
      itemsAction: [],
      status: null,
      actionValue: 0,
      selectedData: [],
      loading: false,
      loader: null,
      fileNameRab: '',

      itemEdit: null,

      isAllow: false,
      category_pr: '',
      category_item: '',
      fpb_category_item_type: '',
      matrix: [],
      rules: {
        docRules: [],
        subjectRules: []
      },
      snackbar: false,
      alertText: '',
      timeout: 2000,
      value: 0,
      counter: 0,
      timer: 100,
      isPdfOpen: false,
      selectedPdf: null,
      showPdf: false,
      info: [],
      dropdownUnit: [],
      file_subject: '',
      tabs: [],

      selectedItemCode: null,
      odooDialog: false,
      odooItem: null,
      dropdownProject: [],
      odoo_project: null,
      selectedItem: null,
      options: {},
      closeFpbForm: {
        comment: null,
        other: ''
      },
      tab: null,
      items: [
        { tab: 'Detail Barang', content: '' },
        { tab: 'Approval', content: '' }
      ],
      detailItems: []
    }
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.prevRoute = from
    })
  },
  computed: {
    ...mapGetters(['getFpbId', 'getUserProfile', 'getFpbList', 'getTabs']),
    pjcaHeaders() {
      const arrHeaders = [
        {
          text: 'No',
          value: 'no',
          align: 'left'
          // sortable: true,
        },
        {
          text: 'Kode Barang',
          value: 'product_code',
          align: 'left'
          // sortable: false,
        },
        {
          text: 'Nama Barang',
          value: 'product_name',
          align: 'left'
          // sortable: false,
        },
        {
          text: 'Qty',
          value: 'qty',
          align: 'left'
          // sortable: false,
        },
        {
          text: 'Harga',
          value: 'price',
          align: 'left'
          // sortable: false,
        },
        {
          text: 'Qty Realisasi',
          value: 'realization_qty',
          align: 'left',
          // sortable: true,
          sortable: false
        },
        {
          text: 'Harga Realisasi',
          value: 'realization_price',
          align: 'left',
          // sortable: true,
          sortable: false
        },
        {
          text: 'Total Realisasi',
          value: 'realization_total',
          align: 'left',
          // sortable: true,
          sortable: false
        },
        {
          text: 'Keterangan',
          value: 'desc',
          align: 'left',
          sortable: false
        }
      ]
      // if (this.detail.status.id === 0) {
      return arrHeaders
      // }
    },

    approvalHeaders() {
      const arrHeaders = [
        { text: 'Sequence', value: 'sequence', align: 'left' },
        { text: 'Doc. No', value: 'document_no', align: 'left' },
        { text: 'Model', value: 'table_name', align: 'center' },
        { text: 'User', value: 'approver_user', align: 'center' },
        {
          text: 'Min. Approver',
          value: 'min_approver',
          align: 'center'
        },
        {
          text: 'Approved by',
          value: 'approved',
          align: 'center'
        },
        {
          text: 'Rejected by',
          value: 'rejected',
          align: 'center'
        },
        {
          text: 'Created at',
          value: 'created_at',
          align: 'center'
        },
        {
          text: 'Update at',
          value: 'updated_at',
          align: 'center'
        }
      ]
      return arrHeaders
    }
  },
  watch: {
    selectedData() {
      // if (this.detail != null) {
      //   if (this.detail.status.id == 0) {
      //     if (this.selectedData.length != 0) {
      //       if (this.selectedData.length == 1) {
      //         this.itemsAction = [
      //           { id: 0, name: '' },
      //           { id: 1, name: 'Edit' },
      //           { id: 2, name: 'Delete' }
      //         ]
      //       }
      //     } else {
      //       this.itemsAction = [{ id: 0, name: '' }]
      //     }
      //   } else if (this.detail.status.id == 2) {
      //     this.itemsAction = [{ id: 0, name: '' }]
      //     // }
      //   } else {
      //     this.itemsAction = [{ id: 0, name: '' }]
      //   }
      // } else {
      //   this.itemsAction = [{ id: 0, name: '' }]
      // }
    }
  },
  created() {
    // if (this.pagePath === 'payreq') {
    //   this.isFpbPageEdit = true
    // }
    // if (this.pagePath === 'cart') {
    //   this.isFpbPageEdit = false
    // }
    // if (this.getTabs !== this.tabs) {
    //   this.setArrTabs()
    // }
    this.loadPage()
  },
  mounted() {
    this.initDropdown()
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },
  methods: {
    ...mapMutations(['setTabs']),
    customSearch(value, search, item) {
      return Object.values(item.product).some(
        (v) => v && v.toString().toLowerCase().includes(search)
      )
    },
    showMsgDialog(pModalType, pStatusMsg, pBtnCancel, pInputType, pInputAtt) {
      const title =
        pInputType === 'file'
          ? 'Upload bukti approvement pada kolom dibawah'
          : ''
      return new Promise((resolve) => {
        this.$swal
          .fire({
            position: 'center',
            icon: pModalType,
            html: '<strong style="font-size:18px;">' + pStatusMsg + '</strong>',
            // html: pStatusMsg,
            showConfirmButton: true,
            showCancelButton: pBtnCancel,
            cancelButtonText: 'No',
            input: pInputType !== undefined ? pInputType : null,
            inputLabel: pInputType !== undefined ? title : null,
            inputAttributes: pInputAtt !== undefined ? pInputAtt : null
          })
          .then((r) => {
            resolve(r)
          })
      })
    },
    showMsgDialog2(pModalType, pStatusMsg, pBtnCancel, pText) {
      let additional = ''
      if (pText !== undefined) {
        additional = '<span style="font-size:12px;">' + pText + '</span>'
      }
      return new Promise((resolve) => {
        this.$swal
          .fire({
            position: 'center',
            icon: pModalType,
            html:
              additional +
              '<br/>' +
              '<strong style="font-size:18px;">' +
              pStatusMsg +
              '</strong>',
            showConfirmButton: true,
            showCancelButton: pBtnCancel,
            confirmButtonText: 'Submit',
            cancelButtonText: 'No',
            input: 'textarea',
            inputLabel: 'Tolong isi alasan pada kolom dibawah sebelum submit',
            inputPlaceholder: 'Tulis sesuatu disini...',
            inputAttributes: {
              'aria-label': 'Tulis sesuatu disini'
            }
          })
          .then((r) => {
            resolve(r)
          })
      })
    },
    showMsgDialog3(pModalType, pStatusMsg, pBtnCancel, yesBtn, noBtn, pText) {
      let additional = ''
      if (pText !== undefined) {
        additional = '<span style="font-size:12px;">' + pText + '</span>'
      }
      return new Promise((resolve) => {
        this.$swal
          .fire({
            position: 'center',
            icon: pModalType,
            html:
              '<strong style="font-size:18px;">' +
              pStatusMsg +
              '</strong>' +
              '<br/>' +
              additional,
            showConfirmButton: true,
            showCancelButton: pBtnCancel,
            confirmButtonText: yesBtn,
            cancelButtonText: noBtn !== '' ? noBtn : 'No'
          })
          .then((r) => {
            resolve(r)
          })
      })
    },
    nextPage() {
      this.loading = true
      try {
        const pageIndex = Number(this.$route.query.idx) + 1
        const path = `/e-catalogue/fpb-list/fpb/${this.getFpbList.fpbList[pageIndex].id}?idx=${pageIndex}`
        if (this.$route.path !== path) this.$router.push(path)
        setTimeout(() => {
          this.loadPage()
        }, 200)
      } catch (e) {
        this.loading = false
      }
    },
    previousPage() {
      this.loading = true
      try {
        const pageIndex = Number(this.$route.query.idx) - 1
        const path = `/e-catalogue/fpb-list/fpb/${this.getFpbList.fpbList[pageIndex].id}?idx=${pageIndex}`
        if (this.$route.path !== path) this.$router.push(path)
        setTimeout(() => {
          this.loadPage()
        }, 200)
      } catch (e) {
        this.loading = false
      }
    },
    async loadPage() {
      // this.loading = true
      // this.detail = null
      // if (this.$route.params.id !== undefined) {
      //   this.$store.commit('setFpbId', this.$route.params.id)
      // }
      // setTimeout(async () => {
      //   if (this.getFpbId !== null) {
      //     this.itemEdit = null
      //     this.actionValue = 0
      //     this.selectedData = []
      //     const url = `${this.proc}fpb/detail/${this.getFpbId}`
      //     // url = url + '&order_by=prd.id&order_type=ASC'
      //     await axios
      //       .get(url)
      //       .then((res) => {
      //         console.log('loadPage>>>', res)
      //         this.detail = res.data.data
      //         this.authorityCheck(res.data.data)
      //         this.loading = false
      //         return null
      //       })
      //       .catch((err) => {
      //         console.log(err)
      //         this.loading = false
      //         return null
      //       })
      //   } else {
      //     this.loading = false
      //     this.$router.push('/e-catalogue/' + this.$route.params.path)
      //   }
      // }, 300)
      this.detailItems = [
        {
          no: 1,
          product_name: 'LAPTOP DELL RYZEN 5',
          product_code: 'AST-0016',
          qty: '5',
          price: '5.600.000',
          realization_qty: '3',
          realization_price: '5.300.000',
          realization_total: '15.900.000',
          desc: ''
        },
        {
          no: 2,
          product_name: 'RAM DDR4',
          product_code: 'AST-3112',
          qty: '3',
          price: '210.000',
          realization_qty: '3',
          realization_price: '210.000',
          realization_total: '630.000',
          desc: ''
        }
      ]
    },
    openItemForm() {
      setTimeout(() => {
        document.getElementById('pjcaItemForm').click()
      }, 200)
    },
    initDropdown() {
      // this.dropdown()
      // this.projectDropdown()
    },
    dropdown() {
      // axios
      //   .get(`${this.proc}master/unit/drop_down?keyword=`)
      //   .then((res) => {
      //     this.dropdownUnit = res.data.data
      //   })
      //   .catch((err) => {
      //     console.log(err)
      //   })
    },
    back() {
      // this.detail = null
      // // this.$store.commit('setFpbId', null)
      // // this.$router.push('/e-catalogue/' + this.$route.params.path)
      // if (this.prevRoute !== undefined) {
      //   // if (this.prevRoute.path !== '/e-catalogue/cart') {
      //   // this.$router.push(this.prevRoute.path)
      //   // } else {
      //   this.$router.push('/e-catalogue/' + this.$route.params.path)
      //   // }
      // } else {
      //   this.$router.push('/e-catalogue/' + this.$route.params.path)
      // }
    },
    rowClick(pValue) {
      setTimeout(() => {
        document.getElementById('pjcaItemForm').click()
      }, 200)
    },
    editDetail() {
      this.isEdit = true
    },
    printDocument() {
      this.loader = 'loading'

      const l = this.loader
      this[l] = !this[l]
      setTimeout(() => {
        this[l] = false
        axios({
          url: `${this.proc}fpb/export_to_pdf/${this.getFpbId}`,
          method: 'GET',
          responseType: 'arraybuffer'
        })
          .then((res) => {
            this.selectedPdf = res.data
            setTimeout(() => {
              this.viewPDF()
            }, 1000)
          })
          .catch((error) => {
            console.log(error)
          })
      }, 2000)

      this.loader = null
    },
    downloadDokumen() {
      if (this.detail.file !== null) {
        if (this.detail.file.length > 0) {
          setTimeout(() => {
            this.dokumenDialog = true
          }, 300)
        } else {
          this.showMsgDialog('warning', 'Belum ada file yang diupload', false)
        }
      } else {
        this.showMsgDialog('warning', 'Belum ada file yang diupload', false)
      }
    },
    readyToDownload(fileLink) {
      window.open(fileLink.file, '_blank')
    },
    unlinkDokumen(fName, pValue) {
      this.loading = true
      const data = {
        module: 'fpb',
        file_name: fName
      }
      axios
        .post(`${this.upload}esanqua/procurement/unlink`, data)
        .then((res) => {
          if (res.data.status_code === '00') {
            this.fileNameRab = ''
            if (pValue === undefined) {
              this.removeFileFromDetail(fName)
            }
          } else {
            this.showMsgDialog('warning', res.data.status_msg, false)
            if (pValue === undefined) {
              if (res.data.status_msg === 'File not found') {
                this.removeFileFromDetail(fName)
              }
            }
          }
          this.loading = false
        })
        .catch((err) => {
          this.loading = false
          this.showMsgDialog('error', err.message, false)
        })
    },
    removeFileFromDetail(fName) {
      for (let i = 0; i < this.detail.file.length; i++) {
        if (this.detail.file[i].file.includes(fName)) {
          this.detail.file.splice(i, 1)
        }
      }
      this.updatePhoto(false)
    },
    closeDokumenDialog() {
      setTimeout(() => {
        this.dokumenDialog = false
      }, 300)
    },
    actionSelect(event) {
      switch (event) {
        case 1:
          // this.initDropdown()
          // this.itemEdit = this.selectedData[0]
          // setTimeout(() => {
          //   document.getElementById('fpbFormEdit').click()
          // }, 500)
          break
        case 2:
          this.delete()
          break
        case 4:
          this.setToDraftItem()
          break
      }
    },

    addItem() {
      // this.initDropdown()
      // setTimeout(() => {
      //   document.getElementById('fpbFormAdd').click()
      // }, 500)
    },
    uploadDoc(event) {
      if (event === 'upload') {
        setTimeout(() => {
          this.rabDialog = true
        }, 500)
      }
      if (event === 'close') {
        if (this.fileNameRab !== '') {
          let fName = ''
          fName = this.fileNameRab
          this.unlinkDokumen(fName, true)
          this.file_subject = null
          // this.$refs.uploadDoc.reset()
        }
        this.$refs.entryFormUpload.reset()
        setTimeout(() => {
          this.rabDialog = false
        }, 500)
      }
      this.fileNameRab = ''
    },
    uploadFileDoc(event) {
      if (event !== null) {
        this.loading = true
        const data = new FormData()
        data.append('file', event)
        data.append('module', 'fpb')
        axios
          .post(`${this.upload}esanqua/procurement`, data)
          .then((res) => {
            if (res.data.status === true) {
              this.showMsgDialog(
                'success',
                'File siap disimpan, Silahkan lanjutkan dengan klik tombol "simpan file"',
                false
              )
              this.fileNameRab = res.data.data.name
              // setTimeout(() => {
              //   this.submitUploadDoc()
              // }, 200)
            } else {
              this.showMsgDialog('warning', res.data.message, false)
            }
            this.loading = false
          })
          .catch((err) => {
            this.loading = false
            this.showMsgDialog('error', err.message, false)
          })
      } else {
        this.fileNameRab = ''
        // this.$refs.uploadDoc.reset()
      }
    },
    submitUploadDoc() {
      this.rules.docRules = [(v) => !!v || 'Tidak ada file yang terupload']
      this.rules.subjectRules = [(v) => !!v || 'Kolom Subject diperlukan']
      const self = this
      setTimeout(function () {
        if (self.$refs.entryFormUpload.validate()) {
          self.updatePhoto(true)
        }
      })
    },
    async updatePhoto(isAddFile) {
      // this.loader = 'loading'
      // const l = this.loader
      // this[l] = !this[l]
      // const file = []
      // if (this.detail.file != null) {
      //   for (let i = 0; i < this.detail.file.length; i++) {
      //     const fName = this.detail.file[i].file.slice(
      //       this.detail.file[i].file.lastIndexOf('/') + 1
      //     )
      //     file.push({
      //       subject: this.detail.file[i].subject,
      //       file: fName
      //     })
      //   }
      // }
      // if (isAddFile) {
      //   file.push({
      //     subject: this.file_subject,
      //     file: this.fileNameRab
      //   })
      // }
      // const form = {
      //   id: this.getFpbId,
      //   file: file
      // }
      // await axios
      //   .post(`${this.proc}fpb/update_file_upload`, form)
      //   .then((res) => {
      //     this[l] = false
      //     if (res.data.status_code === '00') {
      //       this.showMsgDialog('success', 'File berhasil disimpan', false)
      //       this.file_subject = null
      //       this.fileNameRab = ''
      //       document.getElementById('closeFileUpload').click()
      //       this.loadPage()
      //     } else {
      //       this.showMsgDialog('error', res.data.status_msg, false)
      //       this.unlinkDokumen(this.fileNameRab)
      //     }
      //   })
      //   .catch((err) => {
      //     this.showMsgDialog('error', err.message, false)
      //     this[l] = false
      //     this.fileNameRab = ''
      //     this.$refs.entryFormUpload.reset()
      //   })
      // this.loader = null
      // this.isEdit = false
    },
    price(pItem) {
      if (pItem !== undefined) {
        if (pItem !== null) {
          return pItem.toString().replace(/\B(?<!\\d*)(?=(\d{3})+(?!\d))/g, ',')
        }
      }
      return '0'
    },
    percentConvert(detail) {
      const realization = detail.total_realization
      const totalPrice = detail.total_price
      let percent = 0
      if (realization != 0 && totalPrice != 0) {
        percent = (realization / totalPrice) * 100
      }
      return percent
    },

    authorityCheck(item) {
      if (item.approval_matrix !== null) {
        const matrixId = item.approval_matrix[0].document_id
        axios
          .get(
            `${this.oAuth}approval_matrix/document/allow_user?document_id=${matrixId}&application_id=2`
          )
          .then((res) => {
            if (res.data.status_code !== '-99') {
              if (res.data.is_allow_approve !== 0) {
                this.isAllow = true
              } else {
                this.isAllow = false
              }
            } else {
              this.isAllow = false
            }
          })
          .catch((err) => {
            this.showMsgDialog(
              'error',
              err
                ? 'Cek Otoritas error, Silahkan info ke Admin'
                : 'Cek Otoritas error, Silahkan info ke Admin',
              false
            )
          })
      }
    },
    // async fetch() {
    //   this.loading = true
    //   const form = {
    //     id: this.getFpbId
    //   }
    //   await axios
    //     .post(`${this.proc}fpb/fetch_matrix`, form)
    //     .then(async (res) => {
    //       if (res.data.status_code == '00') {
    //         this.loadPage()
    //       }
    //       this.loading = false
    //       this.showMsgDialog(
    //         res.data.status_code == '00' ? 'success' : 'warning',
    //         res.data.status_msg,
    //         false
    //       )
    //     })
    //     .catch((err) => {
    //       console.log(err)
    //       this.loading = false
    //     })
    // },

    viewPDF() {
      if (!this.isPdfOpen) {
        this.pdfDialog = true
        this.isPdfOpen = true
        // this.selectedPdf = 'true'
        // this.selectedPdf = pItem.file
        this.countDownTimer()
      }
    },
    countDownTimer() {
      if (this.counter < this.timer) {
        setTimeout(() => {
          this.counter += 1
          this.value += 10
          this.countDownTimer()
        }, 1)
      } else {
        this.showPdf = true
        this.value = 0
        this.counter = 0
      }
    },
    async openHandler(pdfApp) {
      this.info = []
      const info = await pdfApp.pdfDocument
        .getMetadata()
        .catch(console.error.bind(console))

      if (!info) return
      const props = Object.keys(info.info)
      props.forEach((prop) => {
        const obj = {
          name: prop,
          value: info.info[prop]
        }
        this.info.push(obj)
      })
    },
    pagesRendered(pdfApp) {
      setTimeout(() => (pdfApp.pdfViewer.currentScaleValue = 'page-actual'))
    },
    closePdf() {
      this.pdfDialog = false
      this.isPdfOpen = false
      this.showPdf = false
    }
  }
}
</script>

<style lang="scss" scoped>
.fpb-title {
  position: relative;
  top: 10px;
  margin: 0;
  // width: 50%;
  font-weight: bold;
  // text-align: center;
  font-size: 20px;
}
.items-total-div-1 {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  min-height: 150px;
  width: 100%;
  justify-content: space-between;
  .items-total-div-2 {
    // padding: 10px 0;
    width: '40%';
    .items-total-box {
      border: 1px solid #e0e0e0;
      padding: 20px 10px;
      width: 400px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-end;
      font-size: 14px;
      div {
        border-bottom: 1px solid #e0e0e0;
        width: 100%;
        padding-top: 20px;
        display: flex;
        p {
          margin: 0;
          margin-right: 10px;
          min-width: 80px;
          position: relative;
          span {
            position: absolute;
            right: 0;
          }
        }
      }
    }
  }
  .items-total-div-3 {
    padding: 0 10px;
    width: 60%;
    font-size: 14px;
  }
}
.actionButton {
  font-size: 14px;
  font-weight: bold;
  display: flex;
  align-items: center;
  margin-left: 15px;
}
.fpb-status-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}
.category-line {
  margin: 10px 0 10px 0;
  display: flex;
  align-items: start;
  flex-wrap: wrap;
  line-height: 75px;
}
@media (max-width: 1200px) {
  .category-line-item {
    margin-bottom: 10px;
  }
}
@media (max-width: 808px) {
  .actionButton {
    justify-content: center;
    align-items: flex-start;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: white;
    z-index: 2;
    padding: 10px 0;
  }
  .items-total-div-1 {
    flex-direction: column-reverse;
    .items-total-div-2 {
      width: '100%';
      .items-total-box {
        width: 100%;
      }
    }

    .items-total-div-3 {
      margin-top: 10px;
      width: 100%;
    }
  }
  .tipe-proses {
    margin-top: 5px;
  }
}

@media (max-width: 840px) {
  .fpb-status-section {
    // flex-direction: column;
    flex-wrap: wrap;
  }
}
@media (max-width: 780px) {
  .fpb-title {
    font-size: 3vw;
  }
}
</style>
